import { ref } from 'vue';
import { getOrCreateProcedure } from 'o365-modules';

const modalInitialMatrix = ref(null);
const modalFinalMatrix = ref(null);

let reloadKey = ref(1);
let expandedItems = ref({});

// Workflow Step Procedures
const procCreateSJA = getOrCreateProcedure({ id: "procCreateSJA", procedureName: "astp_SJA_NewSJA" });
const procSJAWorkflowAction = getOrCreateProcedure({ id: "procSJAWorkflowAction", procedureName: "astp_SJA_NewWorkflowAction" });

// SJA Procedures
const procGetMatrix = getOrCreateProcedure({ id: "procGetMatrix", procedureName: "astp_SJA_GetMatrix" });
const procDeleteSJA = getOrCreateProcedure({ id: "procDeleteSJA", procedureName: "astp_SJA_DeleteSJA" });
const procDeleteSJARevision = getOrCreateProcedure({ id: "procDeleteSJARevision", procedureName: "astp_SJA_DeleteSJARevision" });
const procRemoveSignature = getOrCreateProcedure({ id: "procRemoveSignature", procedureName: "astp_SJA_RemoveSignature" });
const procSignSJA = getOrCreateProcedure({ id: "procSignSJA", procedureName: "astp_SJA_Sign" });
const procAddResponsible = getOrCreateProcedure({ id: "procAddResponsible", procedureName: "astp_SJA_AddResponsible" });
const procDeleteTask = getOrCreateProcedure({ id: "procDeleteTask", procedureName: "astp_SJA_DeleteSJATask" });

// Functions
function collapseAll(sjaID = null) {
    let allSummary;
    let allCards;

    if (sjaID) {
        allSummary = document.querySelectorAll(`.tasks-table-${sjaID} .hazards-summary-card`);
        allCards = document.querySelectorAll(`.tasks-table-${sjaID} .hazards-card`);
    } else {
        allSummary = document.querySelectorAll(".hazards-summary-card");
        allCards = document.querySelectorAll(".hazards-card");
    }


    allSummary.forEach(summary => {
        let taskRowNumber = summary.id.match(/\d+/)[0].slice(0, -1);
        expandedItems.value[taskRowNumber] = true;
        summary.classList.add('show');
    });

    allCards.forEach(card => {
        let taskRowNumber = card.id.match(/\d+/)[0].slice(0, -1);
        expandedItems.value[taskRowNumber] = true;
        card.classList.remove('show');
    });
}

function expandAll(sjaID = null) {
    let allSummary;
    let allCards;

    if (sjaID) {
        allSummary = document.querySelectorAll(`.tasks-table-${sjaID} .hazards-summary-card`);
        allCards = document.querySelectorAll(`.tasks-table-${sjaID} .hazards-card`);
    } else {
        allSummary = document.querySelectorAll(".hazards-summary-card");
        allCards = document.querySelectorAll(".hazards-card");
    }

    allSummary.forEach(summary => {
        let taskRowNumber = summary.id.match(/\d+/)[0].slice(0, -1);
        expandedItems.value[taskRowNumber] = false;
        summary.classList.remove('show')
    });

    allCards.forEach(card => {
        let taskRowNumber = card.id.match(/\d+/)[0].slice(0, -1);
        expandedItems.value[taskRowNumber] = false;
        card.classList.add('show');
    });
}

export { reloadKey, expandedItems, procSJAWorkflowAction, procGetMatrix, procCreateSJA, procDeleteSJA, procDeleteSJARevision, procAddResponsible, procDeleteTask, procRemoveSignature, procSignSJA, modalInitialMatrix, modalFinalMatrix, expandAll, collapseAll }